import { types, getEnv, flow, getRoot } from 'mobx-state-tree';
import { Program, fromApi as programFromApi } from './Program';
import { fail } from '../../ModelUtils';

export const SearchStore = types
	.model('SearchStore', {
		state: types.optional(
			types.enumeration('State', ['idle', 'pending', 'done', 'error']),
			'idle'
		),
		keyword: types.maybeNull(types.string),
		items: types.maybeNull(types.array(Program))
	})
	.views(self => ({
		is: (...args) => args.indexOf(self.state) !== -1,

		get root() {
			return getRoot(self);
		},

		get isLoaded() {
			return !!(self.is('done') && self.items && self.items.length);
		},

		get playableItems() {
			return self.hasResults
				? self.items.filter(program => !program.isDisabled)
				: null;
		},

		get hasResults() {
			return !!(self.items && self.items.length);
		},

		get hasPlayableResults() {
			return !!(self.playableItems && self.playableItems.length);
		}
	}))
	.actions(self => ({
		afterAttach() {
			if (self.is('pending')) {
				self.reset();
			}
		},

		search: flow(function* search(keyword) {
			const api = getEnv(self).api;

			if (!self.is('pending')) {
				self.state = 'pending';

				try {
					const items = yield api
						.searchPrograms(keyword)
						.then(({ data, meta }) => data.map(programFromApi));

					self.keyword = keyword;
					self.items = items;
					self.state = 'done';
					return items;
				} catch (ex) {
					self.state = 'idle';
					fail(self, ex);
					return;
				}
			}
		}),

		reset() {
			self.keyword = null;
			self.items = null;
			self.state = 'idle';
		}
	}));
