import {
	assign,
	trim,
	fromPairs,
	split,
	range as _range,
	isEqual,
	isString,
	floor,
	trimEnd
} from 'lodash';
import 'core-js/web/url';
import 'core-js/web/url-search-params';
import { typecheck } from 'mobx-state-tree';

export const guid = (function() {
	let counter = 0;

	return function(prefix = 'o_') {
		let guid = new Date().getTime().toString(32),
			i;

		for (i = 0; i < 5; i++) {
			guid += Math.floor(Math.random() * 65535).toString(32);
		}

		return prefix + guid + (counter++).toString(32);
	};
})();

export const hasClass = ($el, name) =>
	$el.className
		? new RegExp('(^|\\s+)' + name + '(\\s+|$)').test($el.className)
		: false;

export const isDisabled = $el => {
	return typeof $el === 'string'
		? isDisabled(document.getElementById($el))
		: !$el || $el.disabled || hasClass($el, 'disabled');
};

export const failApi = (T, struct) => {
	// simply throw an exception for now
	throw new Error(
		`API Error: ${T.name} failed to be converted to Model (${struct})`
	);
};

export const validateApi = (T, struct) => {
	// typecheck(T, struct);
	return T.is(struct) ? struct : failApi(T, struct);
};

export const isTruthy = item => !!item;

export const waitUntil = (expression, interval = 500, timeout = 10000) => {
	return new Promise((resolve, reject) => {
		let counter = 0;
		let timer = setInterval(() => {
			counter += interval;
			const result = expression();
			if (result) {
				clearInterval(timer);
				resolve(result);
			} else if (counter >= timeout) {
				clearInterval(timer);
				reject();
			}
		}, interval);
	});
};

export const range = (from, to, minVal = NaN, maxVal = NaN) => {
	isNaN(minVal) && (minVal = from);
	isNaN(maxVal) && (maxVal = to);

	if (to >= from) {
		return _range(from, to + 1);
	} else {
		const vals = [];
		for (let i = from; i <= maxVal; i++) {
			vals.push(i);
		}
		for (let i = minVal; i <= to; i++) {
			vals.push(i);
		}
		return vals;
	}
};

export const createDebouncedAggregator = (cb, timeout = 50) => {
	const mergedObj = {};
	let timer;
	return obj => {
		assign(mergedObj, obj);
		if (timer) {
			clearTimeout(timer);
		}
		timer = setTimeout(() => {
			cb(mergedObj);
		}, timeout);
	};
};

const baseGet = (object, path) => {
	path = path.split('.');

	let index = 0;
	const length = path.length;

	while (object != null && index < length) {
		object = object[path[index++]];
	}
	return index && index === length ? object : undefined;
};

export const get = (object, path, defaultValue) => {
	const result = object == null ? undefined : baseGet(object, path);
	return result === undefined ? defaultValue : result;
};

export const anchorFocus = ($el = document.body) => {
	setImmediate(() => {
		const $anchors = [...$el.querySelectorAll('[data-focus-anchor]')];

		if ($anchors.length > 1) {
			$anchors.sort(
				($a, $b) =>
					+$b.getAttribute('data-focus-anchor') -
					+$a.getAttribute('data-focus-anchor')
			);
		}

		for (const $anchor of $anchors) {
			if (!isDisabled($anchor)) {
				$anchor.focus();
				return;
			}
		}
	});
};

export const focus = $el => {
	if (typeof $el === 'string') {
		$el = document.getElementById($el);
	}
	if ($el && !isDetachedFromDOM($el)) {
		setImmediate(() => {
			$el.focus();
		});
	}
};

export const toQuerystr = obj =>
	Object.keys(obj)
		.filter(key => obj[key] !== undefined)
		.map(key => `${key}=${obj[key]}`)
		.join('&');

export const fromQuerystr = str =>
	fromPairs(str.split('&').map(arg => split(arg, '=', 2)));

export const equivQueryStr = (str1, str2) =>
	isEqual(
		isString(str1) ? fromQuerystr(str1) : str1,
		isString(str2) ? fromQuerystr(str2) : str2
	);

export const getCurrentQuery = (_url = window.location.href) => {
	const url = new URL(_url);
	// not sure if searchParams will be available in older Tizens, so let's do it manually
	return url.search ? fromQuerystr(url.search.slice(1)) : {};
};

export const matchesSelector = ($el, selector) => {
	const matchesFn =
		Element.prototype.matches ||
		Element.prototype.msMatchesSelector ||
		Element.prototype.webkitMatchesSelector;
	return matchesFn.call($el, selector);
};

// not sure if Safari in oldest Tizen has support for Element.closest, so polyfill just in case
export const closestAncestor = ($el, selector) => {
	if (Element.prototype.closest) {
		return $el.closest(selector);
	} else {
		if (!document.documentElement.contains($el)) return null;
		do {
			if (matchesSelector($el, selector)) return $el;
			$el = $el.parentElement || $el.parentNode;
		} while ($el !== null && $el.nodeType === 1);
		return null;
	}
};

export const isDetachedFromDOM = $el => !closestAncestor($el, 'body');

export const hlsHasMultiAudio = data => {
	const tags = [];
	data.split(/\n/).forEach(row => {
		const m = row.match(/^#([^:]+):(.+)$/);
		if (m) {
			const [, name, queryStr] = m;
			const tagData = { name };
			// we could split by comma, but there might be commas within quoted string values
			queryStr.replace(
				/(?:^|,)([^=]+)=("[^"]+"|[^,]+)(?=,|$)/g,
				($0, $1, $2) => {
					tagData[$1] = trim($2, '"');
				}
			);
			tags.push(tagData);
		}
	});
	return (
		tags.filter(({ name, TYPE }) => name === 'EXT-X-MEDIA' && TYPE === 'AUDIO')
			.length > 1
	);
};

export const toRem = (px, context = 24) => floor(px / context, 2);

export const parseUrl = url => new URL(url);

// strip protocol and extra chars from the end
export const toHttps = url =>
	'https://' + trimEnd(url, '?').replace(/^https?:\/?\/?/, '');
