import * as Sentry from '@sentry/browser';
import { reaction } from 'mobx';
import {
	types as t,
	getEnv,
	flow,
	getSnapshot,
	addDisposer,
	onPatch,
	typecheck
} from 'mobx-state-tree';
import canAutoPlay from 'can-autoplay';
import { keyBy, throttle, endsWith, assign, isString } from 'lodash';
import { User, fromApi as userFromApi } from './User';
import { Menu, menuItems } from './Menu';
import { Channel, fromApi as channelFromApi } from './pages/tv/Channel';
import { ServerTime } from './ServerTime';
import { Notification } from './Notification';
import { IdleCounter } from './IdleCounter';
import { fail } from './ModelUtils';
import { ApiError } from 'api/ApiClient';
import {
	toQuerystr,
	fromQuerystr,
	equivQueryStr,
	getCurrentQuery,
	parseUrl
} from 'helpers/utils';
import Env from '../Env';

export const Auth = t
	.model('Auth', {
		version: t.string,
		menu: t.maybe(Menu),
		userData: t.maybe(User),
		channels: t.optional(t.map(Channel), {}),
		serverTime: t.maybe(ServerTime)
	})
	.volatile(self => ({
		authData: null,
		notification: null,
		state: 'idle', //t.enumeration("State", ['idle', 'pending', 'done', 'error']),
		idleCounter: null,
		serverTimeSyncTimer: null,
		prevLocation: null,
		location: null,
		isAppHidden: false, // is set to true when another tab is selected or app looses visibility
		isAutoPlayAllowed: false,
		isLoadingFromAddressBar: false
	}))
	.views(self => ({
		is(state) {
			return self.state === state;
		},

		get name() {
			return self.isUser ? self.userData.email : 'guest';
		},

		get isAuthorized() {
			return !!self.authData;
		},

		get isGuest() {
			return self.isAuthorized && !self.isUser;
		},

		get isUser() {
			return !!(self.isAuthorized && self.userData);
		}
	}))
	.actions(self => {
		const autosave = throttle(() => {
			self.autosave();
		}, 1000);

		const onVisibilityChange = () => {
			self.setAppIsHidden(document.hidden);
		};

		return {
			afterCreate: flow(function* afterCreate() {
				// autosave onPatch except some of the most frequent and less important ones
				addDisposer(
					self,
					onPatch(self, patch => {
						if (
							!endsWith(patch.path, '/idleCounter/secs') &&
							!endsWith(patch.path, '/playbackOffset')
						) {
							autosave();
						}
					})
				);

				// subscribe to location changes
				addDisposer(
					self,
					reaction(
						() => self.location,
						location => {
							if (!equivQueryStr(getCurrentQuery(), location)) {
								const queryStr = toQuerystr(location);

								if (Env.platform !== 'web') {
									// update iframe url
									const $ga = document.querySelector('#ga');
									if ($ga && $ga.src) {
										const src = parseUrl($ga.src);
										src.search = queryStr;
										$ga.src = src.href;
									}
								} else {
									// update browser location
									if (!self.isLoadingFromAddressBar) {
										window.history.pushState(null, null, `/?${queryStr}`);
									}

									// update document title
									let pageTitle = 'MYVIDEO: ' +
										self.menu.getTitleOf(location.p) || 'ქართული ტელევიზია';
									if (location.p === 'tv' && location.c) {
										const channel = self.channels.get(`Channel::${location.c}`);
										if (channel) {
											pageTitle += ` - ${channel.name}`;
										}
									}
									document.title = pageTitle;
								}
							}
							self.setIsLoadingFromAddressBar(false);
						}
					)
				);

				self.state = 'idle';
				self.idleCounter = IdleCounter.create();
				self.serverTime = ServerTime.create();
				self.menu = Menu.create({
					items: menuItems
				});

				const { result } = yield canAutoPlay.video({ timeout: 250 });
				self.setIsAutoPlayAllowed(result);

				// configure API client
				const api = getEnv(self).api;
				api.setOption('onAuthUpdate', self.setAuthData);
				api.setOption('onTokensUpdate', self.setAuthData);

				const authData = Env.keyManager.getItem(self.name);
				try {
					self.authData = authData;
				} catch (ex) {
					Sentry.captureException(
						new Error(`Stored auth data has invalid format: ${authData}`)
					);
				}

				try {
					if (self.authData) {
						api.setUserCredentials(self.authData);
						yield self.setEssentials();
					} else {
						yield self.authAsGuest();
					}
				} catch (ex) {
					fail(self, new ApiError(ApiError.LOCK_RESOLUTION_FAILED));
					Sentry.captureException(ex);
					return;
				}

				// listen to visibilitychange
				document.addEventListener('visibilitychange', onVisibilityChange);

				if (Env.platform === 'web') {
					window.onpopstate = self.loadFromAddressBar;
					self.loadFromAddressBar();
				} else {
					self.loadPage('tv'); // TODO: make this flexible after other pages are enabled
				}
			}),

			beforeDestroy() {
				self.autosave();
				document.removeEventListener('visibilitychange', onVisibilityChange);
			},

			authAsGuest: flow(function* auth() {
				if (self.is('pending')) {
					return;
				}
				self.state = 'pending';
				try {
					const api = getEnv(self).api;
					// on success API will invoke a callback and update authData in keyManager
					yield api.authGuest();
					yield self.setEssentials();
					self.state = 'done';

					setImmediate(() => self.loadPage('tv'));
				} catch (ex) {
					self.state = 'idle';
					fail(self, new ApiError(ApiError.TOKEN_REFRESH_FAILED));
					Sentry.captureException(ex);
				}
			}),

			login: flow(function* login(username, password) {
				if (self.is('pending')) {
					return;
				}
				self.state = 'pending';

				try {
					const api = getEnv(self).api;
					yield api.authUser(username, password);
					const userData = yield api.user().then(userFromApi);
					//typecheck(User, userData);
					// if (!User.is(userData)) {
					//     throw new Error(`სამომხმარებლო მონაცემები უცნაურ ფორმატშია.`);
					// }
					self.userData = userData;
					yield self.setEssentials();
					self.state = 'done';

					setImmediate(() => self.loadPage('balance'));
				} catch (ex) {
					self.state = 'idle';
					fail(
						self,
						ex instanceof ApiError
							? new Error(
									`მომხმარებლის სახელი ან პაროლი არასწორია. გადაამოწმეთ თუ შეიძლება.`
							  )
							: ex
					);
				}
			}),

			logout: flow(function* logout() {
				const api = getEnv(self).api;

				// wipe out local user data
				self.authData = null;
				self.userData = undefined;
				self.state = 'idle';
				self.clearNotification();

				// optionally logout from remote service (won't succeed if
				// access/refresh tokens are already invalid)
				try {
					yield api.logout();
				} catch (ex) {}

				setImmediate(self.authAsGuest);
			}),

			fetchChannels: flow(function* fetchChannels() {
				try {
					const api = getEnv(self).api;
					const channels = yield api
						.channels()
						.then(data => data.map(channelFromApi))
						.then(channels => keyBy(channels, ({ key }) => key));
					self.channels = channels;
				} catch (ex) {
					fail(
						self,
						new ApiError(
							ApiError.USER_NOT_AUTHORIZED,
							`არხების სიის ჩამოტვირთვა ვერ მოხერხდა. გადაამოწმეთ ინტერნეტ შეერთება.`
						)
					);
				}
			}),

			setEssentials: flow(function* setEssentials() {
				if (self.isAuthorized) {
					yield self.serverTime.fetchServerTime();
					yield self.fetchChannels();
				}
			}),

			setAuthData(authData) {
				Env.keyManager.setItem(self.name, authData);
				self.authData = authData;
			},

			setAppIsHidden(state) {
				self.isAppHidden = state;
			},

			setError(err, onConfirm = null, overridePrevious = false) {
				if (err instanceof Error) {
					Sentry.captureException(err);
				}

				return self.setNotification(
					'დაფიქსირდა შეცდომა',
					err instanceof Error
						? err.message
						: err ||
								'სცადეთ იმ მოქმედების გამეორება, რომელმაც ეს შეცდომა გამოიწვია.',
					onConfirm,
					overridePrevious
				);
			},

			setAlert(err, onClose) {
				self.setError(err, null, true);
				self.notification.setOnClose(onClose);
				return self.notification;
			},

			setNotification(
				title,
				message,
				onConfirm = null,
				overridePrevious = false
			) {
				if (!self.notification || overridePrevious) {
					self.notification = Notification.create({ title, message });

					if (onConfirm) {
						self.notification.setOnConfirm(onConfirm);
					}

					self.notification.setOnClose(() => self.clearNotification());
					return self.notification;
				}
				return null;
			},

			clearNotification() {
				self.notification = null;
			},

			autosave() {
				localStorage.setItem('myStore', JSON.stringify(getSnapshot(self)));
			},

			loadPage: flow(function*(id) {
				return self.loadPageFromQuery({ p: id });
			}),

			loadPageFromQuery: flow(function*(query) {
				switch (query.p) {
					case 'tv':
						self.menu.playChannel(query.c, query.t);
						break;
					case 'balance':
						if (self.isUser) {
							self.menu.select('balance');
						} else {
							self.menu.select('user');
						}
						break;
					default:
						self.menu.playChannel();
				}
			}),

			loadFromAddressBar() {
				const currentQuery = getCurrentQuery();

				self.setIsLoadingFromAddressBar(true);

				if (!equivQueryStr(currentQuery, self.location)) {
					self.loadPageFromQuery(currentQuery);
				}
			},

			setLocation(location) {
				const query = isString(location) ? fromQuerystr(location) : location;
				let currentQuery = getCurrentQuery();

				if (query.p && currentQuery.p !== query.p) {
					currentQuery = {}; // reset fully if page has changed
				}

				const newLocation = assign({}, currentQuery, query);

				if (!equivQueryStr(newLocation, self.location)) {
					self.prevLocation = self.location;
					self.location = newLocation;
				}
			},

			setIsLoadingFromAddressBar(state) {
				self.isLoadingFromAddressBar = state;
			},

			setIsAutoPlayAllowed(state) {
				self.isAutoPlayAllowed = state;
			}
		};
	});
