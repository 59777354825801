import * as Sentry from '@sentry/browser';
import { floor, throttle } from 'lodash';

// video.js that we use to play streams on the web adds undesirable styles
// to the head of the document, the following suppresses such behavior
window.VIDEOJS_NO_DYNAMIC_STYLE = true;

const version = dotenv.APP_VERSION;

Sentry.init({
	dsn: 'https://af7c73d42d3f4b548c9a5eb8b5d49ed0@sentry.io/1450945',
	release: version
});

const exit = () => {
	window.location.href = 'about:blank';
};

const getUsers = () => {
	try {
		const data = JSON.parse(localStorage.getItem('users'));
		return data && typeof data === 'object' ? data : {}; // null is also typeof 'object' :/
	} catch (ex) {
		return {};
	}
};

const keyManager = {
	setItem(name, value) {
		const users = getUsers();
		users[name] = value;
		localStorage.setItem('users', JSON.stringify(users));
	},

	getItem(name, autoParse = true) {
		const users = getUsers();
		return users[name] ? users[name] : null;
	},

	removeItem(name) {
		const users = getUsers();
		delete users[name];
		localStorage.setItem('users', JSON.stringify(users));
	},

	clear() {
		localStorage.removeItem('users');
	}
};

export default {
	version,
	platform: 'web',
	keyManager, // bad idea
	exit
};
