import { Auth } from './models/Auth';
import { MyVideoApi } from './api/MyVideoApi';
import Env from './Env';

let initialState = { version: Env.version };

const api = new MyVideoApi({
	clientId: dotenv.REACT_APP_API_CLIENT_ID,
	apiSecret: dotenv.REACT_APP_API_CLIENT_SECRET
});

if (localStorage.getItem('myStore')) {
	const snapshot = JSON.parse(localStorage.getItem('myStore'));
	//typecheck(Auth, snapshot);
	if (Auth.is(snapshot) && Env.version === snapshot.version) {
		initialState = snapshot;
	} else {
		localStorage.removeItem('myStore'); // invalidate stored snapshot
	}
}

const mainStore = Auth.create(initialState, { api });

if (dotenv.NODE_ENV === 'development') {
	const { connectReduxDevtools } = require('mst-middlewares');
	connectReduxDevtools(require('remotedev'), mainStore, {
		logChildActions: false
	});
}

export default mainStore;

import('./main');
