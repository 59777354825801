import { types as t, getParent } from 'mobx-state-tree';
import { trimEnd } from 'lodash';
import { validateApi } from 'helpers/utils';

export const Chunk = t
	.model('Chunk', {
		isLive: false,
		startTime: t.number,
		endTime: t.maybeNull(t.number),
		seekOffset: t.number,
		playbackOffset: t.optional(t.number, 0),
		fileUrl: t.string,
		timestamp: t.number
	})
	.views(self => ({
		get channel() {
			return getParent(self);
		},

		get url() {
			// strip protocol and extra chars from the end
			return 'https:' + trimEnd(self.fileUrl, '?').replace(/^https?:/, '');
		},

		get currentTime() {
			return self.startTime + self.playbackOffset;
		},

		isOutdated() {
			return Date.now() - self.timestamp >= 1000 * 60 * 60; // one hour
		},

		isSeekable(time) {
			return self.endTime && time > self.startTime && time < self.endTime;
		}
	}))
	.actions(self => ({
		afterAttach() {
			// https://gitlab.itdc.ge/myvideo/laravel/issues/303
			if (self.endTime > self.channel.serverTime()) {
				self.endTime = self.channel.serverTime();
			}
		},

		setSeekOffset(time) {
			if (self.seekOffset === time) {
				time -= 1; // we got to force update this
			}

			self.seekOffset = time;
			self.playbackOffset = time;
		},

		setPlayBackOffset(time) {
			self.playbackOffset = time;
		}
	}));

export const fromApi = data => {
	const { attributes: a = {} } = data;

	const struct = {
		startTime: a.startTs * 1000,
		endTime: a.endTs ? a.endTs * 1000 : null,
		seekOffset: a.seek * 1000,
		playbackOffset: a.seek * 1000,
		isLive: a.live,
		fileUrl: a.file,
		timestamp: Date.now()
	};

	return validateApi(Chunk, struct);
};
